import React from 'react';

export default function Question(props) {

    const isVisuel = (visuel) => {
        if (visuel !== undefined && visuel !== null) {
            return (<img src={`/uploads/${visuel}`} alt="Visuel"  style={{maxWidth: '600px', maxHeigth: '600px'}} />)
        } else {
            return (null)
        }
    }

    const listAnswers = props.question.answers.map((answer) =>
        <li>{answer.title}</li>
    );

    return (
            <div>
                <p className="card-text h5 fw-bold">
                    {props.question.title}
                </p>
                <div className="text-center my-4">
                    {isVisuel(props.question.imageName)}
                </div>
                {props.question.answers.map(answer =>
                        <div key={answer.id}>
                            <input className="btn-check" type="radio" name="answer" id={answer.id} value={answer.id} />
                            <label className="btn btn-outline-secondary w-100 my-1" htmlFor={answer.id}>
                                {isVisuel(answer.imageName)}
                                {answer.title}
                            </label>
                        </div>
                    )}
            </div>
            )
}
