import React from 'react';
import Question from "./Question";
import Correction from "./Correction";

export default function (props) {

    const quiz = JSON.parse(props.quiz);

    const [activeStep, setActiveStep] = React.useState(0);
    const [quizResult, setQuizResult] = React.useState([]);
    const [showNext, setShowNext] = React.useState(0);
    const [showCorrection, setShowCorrection] = React.useState(0);
    const [userResponse, setUserResponse] = React.useState(0);

    const handleSubmit = async (e) => {

        e.preventDefault();

        const formData = new FormData(e.target);

        if (showCorrection === 0) {

            if (formData.get('answer') !== null) {
                quizResult.push({questionId: quiz.questions[activeStep].id, answerId: parseInt(formData.get('answer'))});
                setShowCorrection(1);
                setUserResponse(formData.get('answer'));
            }
        } else {

            if (!isLastQuestion()) {

                setActiveStep(prevState => prevState + 1);
                setShowCorrection(0);
                setUserResponse(0);
            } else {
                const response = await fetch(`/quiz`, {
                    body: JSON.stringify({quizResult: quizResult}),
                    method: 'POST',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                });

                const json = await response.json();

                window.location.href = `/engagement`;
            }
        }
    }

    const isLastQuestion = () => {
        return (activeStep + 1) === quiz.questions.length;
    }

    if (showCorrection) {
        return (
                <div className='m-auto' style={{width: '100%', maxWidth: '620px'}}>
                    <form onSubmit={handleSubmit}>
                        <Correction question={quiz.questions[activeStep]} userResponse={userResponse} />
                        <div style={{marginTop: 10, textAlign: "center"}}>
                            <button type="submit" className="btn btn-primary">
                                {isLastQuestion() ? 'Terminer' : 'Suivant'}
                            </button>
                        </div>
                    </form>
                </div>
                );
    } else {

        return (
                <div className='m-auto' style={{width: '100%', maxWidth: '620px'}}>
                    <form onSubmit={handleSubmit}>
                        <Question question={quiz.questions[activeStep]} />
                        <div style={{marginTop: 10, textAlign: "center"}}>
                            <button type="submit" className="btn btn-primary">
                                Valider la réponse
                            </button>
                        </div>
                    </form>
                </div>
                );
    }

}