import React from 'react';

export default function Correction(props) {

    const isVisuel = (visuel) => {
        if (visuel !== undefined && visuel !== null) {
            return (<img src={`/uploads/${visuel}`} alt="Visuel"  style={{maxWidth: '600px', maxHeigth: '600px'}} />)
        } else {
            return (null)
        }
    }

    const listAnswers = props.question.answers.map((answer) =>
        <li>{answer.title}</li>
    );

    let isRightAnswer = props.userResponse == props.question.rightAnswer.id ? 1 : 0;

    const getClassName = (answer) => {
        if (answer.id == props.userResponse) {
            if (answer.isCorrect) {
                return ('btn btn-success w-100 my-1')
            } else {
                return ('btn btn-danger w-100 my-1')
            }
        } else {
            return ('btn btn-outline-secondary w-100 my-1')
        }
    }

    return (
            <div>
                <p className="card-text h5 fw-bold">
                    {props.question.title}
                </p>
                <div className="text-center my-4">
                    {isVisuel(props.question.imageName)}
                </div>
            
                {isRightAnswer === 1 &&
                            <p className="text-success fw-bold">Bonne réponse !</p>
                }
            
                {isRightAnswer === 0 &&
                            <div>
                                <p className="text-danger fw-bold">Mauvaise réponse</p>
                                <p className="text-success">La bonne réponse est : {props.question.rightAnswer.title}</p>
                            </div>
                }
            
                {props.question.rightAnswer.explanation &&
                            <p>{props.question.rightAnswer.explanation}</p>
                }
                
                <div className="text-center my-4">
                    {isVisuel(props.question.rightAnswer.explanationImageName)}
                </div>
            
                {props.question.answers.map(answer =>
                        <div key={answer.id}>
                            <label className={getClassName(answer)} htmlFor={answer.id}>
                                {isVisuel(answer.imageName)}
                                {answer.title}
                            </label>
                        </div>
                    )}
            
            </div>
            )
}

